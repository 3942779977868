<template>
  <section class="main-license">
    <div class="main-title">
      <h2>успех гарантирован</h2>
    </div>

    <div class="main-license__items">
      <div class="main-license__item main-license__license">
        <h6>
          государственная лицензия и&nbsp;аккредитация
        </h6>
        <p>
          благодаря этому мы <span>выдаем аттестаты самостоятельно</span>,
          а&nbsp;не&nbsp;через партнеров, как это делают другие школы
        </p>
        <img
          src="/v2/main/license/licenses.webp"
          alt="licenses"
          class="main-license__license_image main-license--desktop pointer"
          @click="showLicensesModal = true"
        >
        <img
          src="/v2/main/license/licenses-mob.webp"
          alt="аттестаты"
          class="main-license__license_image main-license--mob"
          @click="showLicensesModal = true"
        >
      </div>
      <div class="main-license__item main-license__attestat">
        <h6>аттестат гос.&nbsp;образца</h6>
        <p>
          получите аттестат гос.&nbsp;образца, занимаясь
          по&nbsp;российскому стандарту образования ФГОС и&nbsp;ФООП
        </p>
        <img
          src="/v2/main/license/аттестаты.webp"
          alt="аттестаты"
          class="main-license__attestat_image main-license--desktop"
        >
        <img
          src="/v2/main/license/аттестаты-mob.webp"
          alt="аттестаты"
          class="main-license__attestat_image main-license--mob"
        >
      </div>
    </div>

    <su-licenses-modal
      v-if="showLicensesModal"
      @close="showLicensesModal = false"
    />
  </section>
</template>

<script>
import SuLicensesModal from '../school-university/SuLicensesModal.vue';

export default {
  name: 'MainLicense',
  components: { SuLicensesModal },
  data: () => ({
    showLicensesModal: false,
  }),
};
</script>

<style scoped lang="scss">

.main-license {
  .main-title {
    margin-bottom: 2.5rem;

    @include media-down($size-mobile) {
      max-width: calc(var(--scale) * 334);
    }
  }

  &__items {
    display: flex;
    gap: calc(var(--scale) * 20);

    @include media-down($size-tablet) {
      gap: calc(var(--scale) * 8);
    }

    @include media-down($size-mobile) {
      flex-direction: column;
    }
  }

  &__item {
    width: 100%;
    height: calc(var(--scale) * 720);
    padding: calc(var(--scale) * 32) calc(var(--scale) * 40);
    position: relative;
    background-color: #FFFFFF;
    border-radius: calc(var(--scale) * 60);
    overflow: hidden;

    @include media-down($size-tablet) {
      height: calc(var(--scale) * 512);
      padding: calc(var(--scale) * 16) calc(var(--scale) * 14) calc(var(--scale) * 16) calc(var(--scale) * 16);
      border-radius: calc(var(--scale) * 24);
    }

    h6 {
      margin-bottom: calc(var(--scale) * 20);

      font-weight: 500;
      font-size: calc(var(--scale) * 64);
      line-height: 95%;
      letter-spacing: -0.02em;
      color: #02120F;

      @include media-down($size-tablet) {
        margin-bottom: calc(var(--scale) * 8);
        font-size: calc(var(--scale) * 40);
        line-height: 110%;
        letter-spacing: -0.04em;
      }
    }

    p {
      max-width: calc(var(--scale) * 752);

      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #A7ABAC;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 18);
      }

      span {
        color: #FF5319;
      }
    }
  }

  &__license {
    &_image {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &__attestat {
    max-width: calc(var(--scale) * 601);

    &_image {
      width: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .main-license--mob {
    display: none;

    @include media-down($size-tablet) {
      display: block;
    }
  }

  .main-license--desktop {
    @include media-down($size-tablet) {
      display: none;
    }
  }
}

</style>
