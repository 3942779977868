<template>
  <section class="main-necessary">
    <div class="main-title">
      <h2><span>здесь вы&nbsp;найдете</span> все&nbsp;необходимое</h2>
    </div>
    <div class="main-necessary__items">
      <nuxt-link
        class="main-necessary__item main-necessary__item--short"
        to="/online-school"
      >
        <h3>домашняя школа</h3>
        <p>получите качественное образование, не&nbsp;выходя из&nbsp;дома</p>
        <button class="m-btn main-necessary__item_arrow main-necessary__item_arrow--yellow">
          <span>узнать больше</span>
          <img
            src="/v2/main/programs/arrow.svg"
            alt="->"
          >
        </button>
      </nuxt-link>
      <nuxt-link
        class="main-necessary__item main-necessary__item--short"
        to="/catalog/repetitor"
      >
        <h3>репетиторы</h3>
        <p>подними свой уровень знаний и&nbsp;покори&nbsp;вершину успеха</p>
        <button class="m-btn main-necessary__item_arrow main-necessary__item_arrow--violet">
          <span>смотреть всех</span>
          <img
            src="/v2/main/programs/arrow.svg"
            alt="->"
          >
        </button>
      </nuxt-link>
      <div
        class="main-necessary__item main-necessary__item--short"
        tabindex="0"
        role="button"
        @click="openModal(MODAL_TYPES.skills)"
      >
        <h3>курсы</h3>
        <p>развивай свои таланты, осваивай новые хобби и расширяй кругозор</p>
        <button class="m-btn main-necessary__item_arrow main-necessary__item_arrow--green">
          <span>смотреть все курсы</span>
          <img
            src="/v2/main/programs/arrow.svg"
            alt="->"
          >
        </button>
      </div>
      <div class="main-necessary__item main-necessary__item--long">
        <h3>центр подготовки к&nbsp;егэ&nbsp;и&nbsp;огэ</h3>
        <div class="main-necessary__item_actions">
          <nuxt-link
            to="/ege"
            class="main-necessary__item_arrow main-necessary__item_arrow--blue"
          >
            <span><span>подготовка</span> к егэ</span>
            <img
              src="/v2/main/programs/arrow.svg"
              alt="->"
            >
          </nuxt-link>
          <nuxt-link
            to="/oge"
            class="main-necessary__item_arrow main-necessary__item_arrow--blue"
          >
            <span><span>подготовка</span> к огэ</span>
            <img
              src="/v2/main/programs/arrow.svg"
              alt="->"
            >
          </nuxt-link>
        </div>
      </div>
      <div class="main-necessary__item main-necessary__item--long">
        <h3>экстернат для&nbsp;детей и&nbsp;взрослых</h3>
        <div class="main-necessary__item_actions">
          <nuxt-link
            to="/externat"
            class="main-necessary__item_arrow main-necessary__item_arrow--green"
          >
            <span>для детей</span>
            <img
              src="/v2/main/programs/arrow.svg"
              alt="->"
            >
          </nuxt-link>
          <nuxt-link
            to="/externat-dlya-vzroslyh"
            class="main-necessary__item_arrow main-necessary__item_arrow--green"
          >
            <span>для взрослых</span>
            <img
              src="/v2/main/programs/arrow.svg"
              alt="->"
            >
          </nuxt-link>
        </div>
      </div>
      <nuxt-link
        class="main-necessary__item main-necessary__item--short"
        to="/proforientacia"
      >
        <h3>профориентация</h3>
        <p>подготовьтесь к&nbsp;поступлению и&nbsp;сделайте правильный выбор</p>
        <button class="m-btn main-necessary__item_arrow main-necessary__item_arrow--blue">
          <span>пройти тест</span>
          <img
            src="/v2/main/programs/arrow.svg"
            alt="->"
          >
        </button>
      </nuxt-link>
      <div
        class="main-necessary__item main-necessary__item--short"
        tabindex="0"
        role="button"
        @click="openModal(MODAL_TYPES.freeLesson)"
      >
        <h3>бесплатные занятия</h3>
        <button class="m-btn main-necessary__item_arrow main-necessary__item_arrow--green">
          <span>записаться на урок</span>
          <img
            src="/v2/main/programs/arrow.svg"
            alt="->"
          >
        </button>
      </div>
      <div
        class="main-necessary__item main-necessary__item--short"
        tabindex="0"
        role="button"
        @click="openModal(MODAL_TYPES.demo)"
      >
        <h3>демо-доступ к&nbsp;платформе</h3>
        <button class="m-btn main-necessary__item_arrow main-necessary__item_arrow--yellow">
          <span>получить доступ</span>
          <img
            src="/v2/main/programs/arrow.svg"
            alt="->"
          >
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { MODAL_TYPES } from '../common/AppHeader/data';

export default {
  name: 'MainNecessary',
  data: () => ({
    MODAL_TYPES,
  }),
  methods: {
    ...mapActions('appHeaderModals', {
      openModal: 'openModal',
    }),
  },
};
</script>

<style scoped lang="scss">

.main-necessary {
  .main-title {
    margin-bottom: 2.5rem;
  }

  .main-necessary__items {
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--scale) * 20);

    @include media-down($size-tablet) {
      gap: calc(var(--scale) * 8);
    }
  }

  .main-necessary__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: calc(var(--scale) * 300);
    padding: calc(var(--scale) * 32) calc(var(--scale) * 40) calc(var(--scale) * 30);
    background-color: #FFFFFF;
    border-radius: calc(var(--scale) * 40);

    @include media-down($size-tablet) {
      border-radius: calc(var(--scale) * 32);
      height: calc(var(--scale) * 180);
      padding: calc(var(--scale) * 22) calc(var(--scale) * 16) calc(var(--scale) * 16);
    }

    h3 {
      margin-bottom: calc(var(--scale) * 20);

      font-weight: 500;
      font-size: calc(var(--scale) * 64);
      line-height: 95%;
      letter-spacing: -0.02em;
      color: #02120F;

      @include media-down($size-tablet) {
        margin-bottom: calc(var(--scale) * 8);
        font-size: calc(var(--scale) * 28);
      }
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #A7ABAC;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 16);
      }
    }

    .main-necessary__item_arrow {
      margin-top: auto;
      transition: 300ms;
      transition-timing-function: linear;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-inline: calc(var(--scale) * 32);
      height: calc(var(--scale) * 72);
      border-radius: calc(var(--scale) * 24);

      @include media-down($size-tablet) {
        height: auto;
        padding: calc(var(--scale) * 11) calc(var(--scale) * 16) calc(var(--scale) * 13);
        border-radius: calc(var(--scale) * 16);
        gap: calc(var(--scale) * 10);
      }

      img {
        width: calc(var(--scale) * 12);

        @include media-down($size-tablet) {
          width: calc(var(--scale) * 10);
        }
      }

      span {
        font-weight: 500;
        font-size: calc(var(--scale) * 26);
        color: #ffffff;

        @include media-down($size-tablet) {
          font-size: calc(var(--scale) * 18);
        }

        span {
          @include media-down($size-tablet) {
            display: none;
          }
        }
      }

      &.main-necessary__item_arrow--green {
        background-color: #E3FFF7;

        span {
          color: #01412E;
        }

        img {
          filter: invert(18%) sepia(19%) saturate(3358%) hue-rotate(125deg) brightness(95%) contrast(99%);
        }
      }

      &.main-necessary__item_arrow--blue {
        background-color: #CEF9FF;

        span {
          color: #0A4183;
        }

        img {
          filter: invert(16%) sepia(34%) saturate(5767%) hue-rotate(204deg) brightness(92%) contrast(92%)
        }
      }

      &.main-necessary__item_arrow--violet {
        background-color: #E6CEFF;

        span {
          color: #5237E6;
        }

        img {
          filter: invert(31%) sepia(93%) saturate(6296%) hue-rotate(248deg) brightness(91%) contrast(98%);
        }
      }

      &.main-necessary__item_arrow--yellow {
        background-color: #FFF0D1;

        span {
          color: #FF5319;
        }

        img {
          filter: invert(44%) sepia(42%) saturate(4697%) hue-rotate(349deg) brightness(99%) contrast(103%);
        }
      }
    }

    &_actions {
      margin-top: auto;
      display: flex;
      gap: calc(var(--scale) * 8);

      @include media-down($size-tablet) {
        gap: calc(var(--scale) * 4);
      }

      .main-necessary__item_arrow {
        display: flex;
        align-items: center;
        gap: calc(var(--scale) * 16);
        width: auto;
        padding-inline: calc(var(--scale) * 32);

        @include media-down($size-tablet) {
          gap: calc(var(--scale) * 10);
          padding-inline: calc(var(--scale) * 16);
        }
      }
    }

    &--short {
      width: calc(33.3% - (var(--scale) * 40 / 3));

      @include media-down($size-tablet) {
        width: calc(50% - var(--scale) * 4);
      }

      @include media-down($size-mobile) {
        width: 100%;
      }

      .main-necessary__item_arrow {
        span {
          display: block;
          white-space: nowrap;
          width: 0;
          overflow: hidden;
          transition: 300ms;
          transition-timing-function: linear;

          @include media-down($size-tablet) {
            width: auto;
          }
        }
      }

      &:hover {
        .main-necessary__item_arrow {
          width: auto;
          gap: calc(var(--scale) * 16);
        }

        .main-necessary__item_arrow img {
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
        }

        .main-necessary__item_arrow span {
          color: #ffffff;
          width: 100%;
        }

        .main-necessary__item_arrow--green {
          background-color: #22AE9D;
        }

        .main-necessary__item_arrow--blue {
          background-color: #0B72BE;
        }

        .main-necessary__item_arrow--violet {
          background-color: #846EFB;
        }

        .main-necessary__item_arrow--yellow {
          background-color: #FF9C12;
        }
      }
    }

    &--long {
      width: calc(50% - var(--scale) * 10);

      @include media-down($size-tablet) {
        width: calc(50% - var(--scale) * 4);
      }

      @include media-down($size-mobile) {
        width: 100%;
      }

      .main-necessary__item_arrow:hover {
        img {
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
        }

        span {
          color: #ffffff;
          width: 100%;
        }

        &.main-necessary__item_arrow--green {
          background-color: #22AE9D;
        }

        &.main-necessary__item_arrow--blue {
          background-color: #0B72BE;
        }
      }
    }
  }

}

</style>
