<template>
  <section class="main-care">
    <div class="main-care__title">
      <h2>мы&nbsp;заботимся о&nbsp;каждом ученике</h2>
      <p>
        обеспечиваем комфортную и&nbsp;безопасную атмосферу
        для&nbsp;обучения и&nbsp;развития вашего&nbsp;ребенка
      </p>
    </div>

    <div class="main-care__items">
      <div class="main-care__block main-care__block-1">
        <h6 class="main-care__block_title">
          психологическая поддержка
        </h6>
        <p class="main-care__block_text">
          мы&nbsp;создали комфортную среду, где&nbsp;каждый ученик может получить помощь психолога.
          профессионалы окажут поддержку в&nbsp;решении личных проблем, помогут справиться
          со&nbsp;стрессом и&nbsp;найти внутренние ресурсы
        </p>
      </div>

      <div class="main-care__block main-care__block-2">
        <h6 class="main-care__block_title">
          кураторская поддержка
        </h6>
        <p class="main-care__block_text">
          каждый ученик имеет своего куратора и&nbsp;наставника, который готов помочь с&nbsp;любыми
          вопросами, связанными с&nbsp;учебой, и&nbsp;направить на&nbsp;верный путь к&nbsp;успеху
        </p>
      </div>

      <div class="main-care__block main-care__block-3">
        <h6 class="main-care__block_title">
          профориентация
        </h6>
        <p class="main-care__block_text">
          отдел профориентации помогает ученикам определить свои интересы, найти свою
          стезю&nbsp;и&nbsp;сделать правильный выбор для&nbsp;будущей профессии
        </p>

        <nuxt-link to="/proforientacia">
          <span>перейти к тестированию</span>
          <img
            src="/v2/main/programs/arrow.svg"
            alt="->"
          >
        </nuxt-link>
      </div>

      <div class="main-care__block main-care__block-4">
        <h6 class="main-care__block_title">
          комьюнити для&nbsp;родителей и&nbsp;чат‑бот для&nbsp;учеников
        </h6>
        <p class="main-care__block_text">
          место для&nbsp;общения, обмена опытом и&nbsp;поддержки 24/7
        </p>

        <a
          href="https://t.me/synergyschool"
          target="_blank"
        >
          <img
            src="/v2/school-university/footer/tg.png"
            alt="tg"
          >
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MainCare',
};
</script>

<style scoped lang="scss">

.main-care {
  .main-care__title {
    display: flex;
    gap: 1.25rem;
    margin-bottom: 2.5rem;

    @include media-down($size-mobile) {
      flex-direction: column;
      gap: 0.8rem;
    }

    h2 {
      width: calc(var(--scale) * 910);
      flex-shrink: 0;

      font-weight: 500;
      font-size: calc(var(--scale) * 86);
      line-height: 110%;
      letter-spacing: -0.04em;
      color: #02120F;

      @include media-down($size-tablet) {
        width: calc(var(--scale) * 364);
        font-size: calc(var(--scale) * 40);
      }

      @include media-down($size-mobile) {
        width: calc(var(--scale) * 334);
      }
    }

    p {
      font-weight: 500;
      font-size: 2rem;
      line-height: 110%;
      color: #A7ABAC;

      @include media-down($size-tablet) {
        font-size: 1.8rem;
        letter-spacing: -0.02em;
      }

      @include media-down($size-mobile) {
        width: calc(var(--scale) * 334);
      }
    }
  }

  .main-care__items {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 1.25rem / 2));
    gap: 1.25rem;

    @include media-down($size-tablet) {
      grid-template-columns: repeat(2, calc(50% - 0.4rem));
      grid-template-rows: calc(var(--scale) * 512) calc(var(--scale) * 512);
      gap: 0.8rem;
    }

    @include media-down($size-mobile) {
      grid-template-columns: 100%;
      grid-template-rows: repeat(4, 51.2rem);
    }
  }

  .main-care__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2.5rem;
    position: relative;
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    border-radius: 2.5rem;

    @include media-down($size-tablet) {
      padding: 1.6rem 1.4rem 1.6rem 1.6rem;
    }

    &_title {
      margin-bottom: 0.75rem;

      font-style: normal;
      font-weight: 500;
      font-size: 3rem;
      line-height: 110%;
      letter-spacing: -0.02em;
      color: #02120F;

      @include media-down($size-tablet) {
        margin-bottom: 0.8rem;
        font-size: 4rem;
        letter-spacing: -0.04em;
      }
    }

    &_text {
      font-style: normal;
      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #A7ABAC;

      @include media-down($size-tablet) {
        font-size: 1.8rem;
        line-height: 110%;
      }
    }

    &-1 {
      background-image: url("/v2/main/care/heart.webp");
      background-position: bottom;
      background-size: 100% auto;

      .main-care__block_text {
        max-width: calc(var(--scale) * 560);
      }

      @include media-down($size-tablet) {
        background-image: url("/v2/main/care/heart-mob.webp");
      }
    }

    &-2 {
      background-image: url("/v2/main/care/tutors.webp");
      background-position: bottom;
      background-size: 100% auto;

      @include media-down($size-tablet) {
        background-image: url("/v2/main/care/tutors-mob.webp");
      }
    }

    &-3 {
      margin-top: calc(-1 * var(--scale) * 227);

      background-image: url("/v2/main/care/proftest.webp");
      background-position: bottom center;
      background-size: 100% auto;

      a {
        margin-top: auto;
        display: flex;
        align-items: center;
        padding: calc(var(--scale) * 19) calc(var(--scale) * 32) calc(var(--scale) * 22);
        gap: calc(var(--scale) * 16);

        background-color: #FFF0D1;
        backdrop-filter: blur(5px);
        border-radius: calc(var(--scale) * 24);

        @include media-down($size-tablet) {
          padding: calc(var(--scale) * 13) calc(var(--scale) * 16) calc(var(--scale) * 17);
          border-radius: calc(var(--scale) * 16);
          gap: calc(var(--scale) * 10);
        }

        img {
          width: calc(var(--scale) * 12);
          filter: invert(48%) sepia(63%) saturate(4812%) hue-rotate(349deg) brightness(100%) contrast(103%);

          @include media-down($size-tablet) {
            width: calc(var(--scale) * 10);
          }
        }

        span {
          font-weight: 500;
          font-size: calc(var(--scale) * 26);
          line-height: 120%;
          color: #FF5319;

          @include media-down($size-tablet) {
            font-size: calc(var(--scale) * 20);
            line-height: 100%;
            letter-spacing: -0.03em;
          }
        }

        &:hover {
          background-color: #FF5319;

          img {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          }

          span {
            color: #ffffff;
          }
        }
      }

      @include media-down($size-tablet) {
        margin-top: 0;
        background-image: url("/v2/main/care/proftest-mob.webp");
      }
    }

    &-4 {
      background-image: url("/v2/main/care/community.webp");
      background-position: bottom right;
      background-size: auto 100%;

      @include media-down($size-tablet) {
        background-size: 100% auto;
      }

      .main-care__block_title {
        max-width: calc(var(--scale) * 405);
      }

      .main-care__block_text {
        margin-bottom: auto;
        max-width: calc(var(--scale) * 405);
      }

      @include media-down($size-tablet) {
        background-image: url("/v2/main/care/community-mob.webp");
      }

      a img {
        width: calc(var(--scale) * 72);

        @include media-down($size-tablet) {
          width: calc(var(--scale) * 56);
        }
      }
    }

    &-1,
    &-4 {
      height: calc(var(--scale) * 493);

      @include media-down($size-tablet) {
        height: 100%;
      }
    }

    &-2,
    &-3 {
      height: calc(var(--scale) * 720);

      @include media-down($size-tablet) {
        height: 100%;
      }

      .main-care__block_text {
        max-width: calc(var(--scale) * 615);
      }
    }
  }
}

</style>
